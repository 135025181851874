import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import ContentBlock from '../components/ContentBlock';

const NotFoundPage = () => {
  const {
    site: {
      globalSeo: { titleSuffix },
    },
  } = useStaticQuery(graphql`
    query NotFoundPageQuery {
      site: datoCmsSite {
        globalSeo {
          titleSuffix
        }
      }
    }
  `);

  return (
    <Layout>
      <HelmetDatoCms
        title="404, Page Not Found"
        titleTemplate={`%s${titleSuffix}`}
      >
        <meta name="robots" content="noindex, follow" />
      </HelmetDatoCms>
      <main>
        <Banner heading="404, Page Not Found" />
        <ContentBlock html="<p>Sorry, we couldn’t find that page, please check the URL.</p>" />
      </main>
    </Layout>
  );
};

export default NotFoundPage;
